import aboutpic from "./components/Access/mePhoto.jpg"

const header = {
  homepage: 'https://yazadsidhwa.com',
  title: 'YS' ,
}

const about = {
  photo:aboutpic,
  name: 'Yazad Sidhwa',
  role: 'Computer Science Student at Texas A&M University',
  description:
    '',
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/yazad-sidhwa/',
    github: 'https://github.com/yazads/',
  },
}

const projects = [
  {
    name: 'SolView',
    description:
      'With the rise in popularity, NFTs have become mainstream, but maybe not for the right reasons. The potential to buy an image and sell it for unprecedented profit is the main reason people buy NFTs, but if the space is going to be taken seriously, the art must speak for itself. Solview is a platform that strips NFTs of their value and focuses on the art.',
    stack: ['ReactJS', 'Flask', 'SolanaAPI','PostgreSQL', 'Material-UI'],
    sourceCode: 'https://github.com/solview-art/solswap',
    livePreview: 'https://solview.art/',
  },
  {
    name: 'ZachChat',
    description:
      'The lack of communication within the engineering department at Texas A&M seems to be growing more and more prominent. ZachChat is able to address the issues and connect Aggie Engineers in an easy and modern way. Users are able to chat and interact with one another through the use of a chatbot along with a community forum that discusses topics within all engineering majors.',
    stack: ['ReactJS', 'AWS Amplify', 'Flask','AntDesign','Heroku PostgreSQL',''],
    sourceCode: 'https://github.com/dylansloann/P3-CSCE315',
    livePreview: 'https://www.zachchat.com/',
  },
  {
    name: 'Elon Money Bot',
    description:
      "In the past, there was a recent surge in Elon Musk's infleunce and the rise of cryptocurrencies and stocks. With that in mind, we created a trading bot in order to exploit the tweets of influential figures alike Elon in order to make instantaneous trades and maximize dividends on both stocks and cryptocurrencies.", 
    stack: ['Python', 'Selenium', 'Twint', 'AlpacaAPI', 'TwitterAPI'],
    sourceCode: 'https://github.com/yazads/elonmoneybot',
    livePreview: 'https://devpost.com/software/elonmoneybot',
  },
]

const skills = [
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'AWS',
  'Heroku',
  'MySQL',
  'PostgreSQL',
  'React',
  'Redux',
  'Flask',
  'Git',
  'GitLab',
  'Bitbucket',
  'Jira',
  'Confluence',
  'Python',
  'C++',
  'Java'
]

const contact = {
  email: 'yazsid01@gmail.com',
}

export { header, about, projects, skills, contact }
